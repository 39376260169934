import React, { useState, useEffect } from 'react';
import '../styles/Home.css';

function Home() {
  return (
    <div className="home-container">
      <img src="/BUDTOPIA.png" alt="Budtopia" className="budtopia-image"/>
      <p className="coming-soon-text">
        COMING SOON!
      </p>
    </div>
  );
}

export default Home;

